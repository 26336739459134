/* Add this to your App.css or a separate CSS file for Navbar */
.navbar {
  background-color: #333;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.navbar a {
  float: left;
  display: block;
  color: white;
  text-align: center;
  padding: 14px 20px;
  text-decoration: none;
}

.navbar-links {
  float: right;
}

.navbar-logo {
  font-weight: bold;
}

